


























import Vue from 'vue';

export default Vue.extend({
    components: {},

    props: {
        isPlaying: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {};
    }
});
