











import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import StickyPlayerControls from './StickyPlayerControls.vue';
import StickyPlayerNowPlaying from './StickyPlayerNowPlaying.vue';
import { Track, Playlist } from './types';

@Component({
    components: {
        StickyPlayerControls,
        StickyPlayerNowPlaying
    }
})
export default class StickyPlayer extends Vue {
    private playlists: Playlist[] = [];

    private currentPlaylistId = '';

    private currentTrackIndex = -1;

    private isPlaying = false;

    private isPlayerEnabled = false;

    private audio: HTMLAudioElement | null = null;

    created() {
        this.getPlaylists();

        window.addEventListener('rsx-player:playlist-selected', (event: unknown) => {
            const id = (event as CustomEvent).detail;
            console.log('StickyPlayer', id);
            this.currentPlaylistId = id;
            this.playNext();
        });
    }

    get currentPlaylist(): Playlist | null {
        const currentPlaylist = this.playlists.find(
            playlist => playlist.id === this.currentPlaylistId
        );
        return currentPlaylist || null;
    }

    get currentTrack(): Track | null {
        if (this.currentPlaylist && this.currentTrackIndex >= 0) {
            const { tracks } = this.currentPlaylist;
            return tracks[this.currentTrackIndex];
        }

        return null;
    }

    async getPlaylists(): Promise<void> {
        const response = await axios.get('/assets/music-player/playlist.json');
        this.playlists = response.data as Playlist[];
        this.currentPlaylistId = this.playlists?.find(p => p?.tracks?.length > 0)?.id || '';
    }

    playNext(): void {
        this.pauseMusic();
        this.isPlayerEnabled = true;

        let newTrackIndex = this.currentTrackIndex + 1;

        const { tracks } = this.currentPlaylist as Playlist;

        if (newTrackIndex >= tracks.length) {
            newTrackIndex = 0;
        }

        const track = tracks[newTrackIndex];

        this.audio = new Audio(track.source);
        this.audio.addEventListener('ended', () => {
            this.playNext();
        });
        this.audio.play();
        this.isPlaying = true;
        this.currentTrackIndex = newTrackIndex;
    }

    playPrevious(): void {
        this.pauseMusic();
        this.isPlayerEnabled = true;

        let newTrackIndex = this.currentTrackIndex - 1;

        const { tracks } = this.currentPlaylist as Playlist;

        if (newTrackIndex < 0) {
            newTrackIndex = tracks.length - 1;
        }

        const track = tracks[newTrackIndex];

        this.audio = new Audio(track.source);
        this.audio.addEventListener('ended', () => {
            this.playNext();
        });
        this.audio.play();
        this.isPlaying = true;
        this.currentTrackIndex = newTrackIndex;
    }

    pauseMusic(): void {
        if (this.audio?.constructor?.name === 'HTMLAudioElement') {
            this.audio.pause();
            this.isPlaying = false;
        }
    }

    resumeMusic(): void {
        if (this.audio) {
            this.audio.play();
        } else {
            this.playNext();
        }
    }

    onButtonClicked(buttonName: string) {
        if (['play', 'pause'].includes(buttonName)) {
            const shouldPlay = buttonName === 'play';
            if (!shouldPlay) {
                this.pauseMusic();
            } else {
                this.resumeMusic();
            }
            this.isPlaying = shouldPlay;
        } else if (buttonName === 'previous-track') {
            this.playPrevious();
        } else if (buttonName === 'next-track') {
            this.playNext();
        } else if (buttonName === 'next-playlist') {
            if (this.currentPlaylist) {
                const playlistIndex = this.playlists.indexOf(this.currentPlaylist);
                if (playlistIndex + 1 < this.playlists.length) {
                    this.currentPlaylistId = this.playlists[playlistIndex + 1].id;
                } else {
                    this.currentPlaylistId = this.playlists[0].id;
                }
            }
            this.currentTrackIndex = -1;
            this.playNext();
        }
    }
}
