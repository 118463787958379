















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Playlist, Track } from './types';
import StickyPlayerEqAnimation from './StickyPlayerEqAnimation.vue';

@Component({
    components: {
        StickyPlayerEqAnimation
    }
})
export default class StickyPlayerNowPlaying extends Vue {
    @Prop() readonly isPlaying = false;

    @Prop() readonly track: Track | null = null;

    @Prop() readonly playlist: Playlist | null = null;

    get trackTitle(): string {
        if (this.track?.title) {
            return this.track.title.length > 40
                ? `${this.track.title.substr(0, 40)}...`
                : this.track.title;
        }
        return this.playlist?.title || 'Select something to play';
    }

    get albumArt(): string {
        return this.playlist?.albumArt || 'https://picsum.photos/seed/picsum/600/600';
    }
}
