<template>
    <div class="equaliser-container" :class="{ stopped }">
        <ol class="equaliser-column">
            <li class="colour-bar"></li>
        </ol>
        <ol class="equaliser-column">
            <li class="colour-bar"></li>
        </ol>
        <ol class="equaliser-column">
            <li class="colour-bar"></li>
        </ol>
        <ol class="equaliser-column">
            <li class="colour-bar"></li>
        </ol>
        <ol class="equaliser-column">
            <li class="colour-bar"></li>
        </ol>
    </div>
</template>

<script>
export default {
    props: {
        stopped: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style scoped lang="scss">
.equaliser-container {
    height: 10px;
    width: 20px;
    margin: 0 0;
    padding: 0 0 0 0;
    position: relative;
}

.colour-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10px;
    background: #999797;
}

.equaliser-column {
    width: 2px;
    float: left;
    margin: 0 1px 0 0;
    padding: 0;
    height: 10px;
    position: relative;
    list-style-type: none;
}

.equaliser-column:nth-child(1) .colour-bar {
    animation: color-bar 2s 1s ease-out alternate infinite;
}

.equaliser-column:nth-child(2) .colour-bar {
    animation: color-bar 2s 0.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(3) .colour-bar {
    animation: color-bar 2s 1.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(4) .colour-bar {
    animation: color-bar 2s 0.25s ease-out alternate infinite;
}

.equaliser-column:nth-child(5) .colour-bar {
    animation: color-bar 2s 2s ease-out alternate infinite;
}

.equaliser-column:last-child {
    margin-right: 0;
}

.stopped {
    .colour-bar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: #999797;
    }

    .equaliser-column:nth-child(1) .colour-bar {
        animation: none;
    }

    .equaliser-column:nth-child(2) .colour-bar {
        animation: none;
    }

    .equaliser-column:nth-child(3) .colour-bar {
        animation: none;
    }

    .equaliser-column:nth-child(4) .colour-bar {
        animation: none;
    }

    .equaliser-column:nth-child(5) .colour-bar {
        animation: none;
    }

    .equaliser-column:last-child {
        margin-right: 0;
    }
}

@keyframes color-bar {
    0% {
        height: 1px;
        background: #999797;
    }
    10% {
        height: 3px;
        background: #999797;
    }
    20% {
        height: 5px;
        background: #999797;
    }
    30% {
        height: 2px;
        background: #999797;
    }
    40% {
        height: 7px;
        background: #999797;
    }
    50% {
        height: 9px;
        background: #999797;
    }
    60% {
        height: 3px;
        background: #999797;
    }
    70% {
        height: 8px;
        background: #999797;
    }
    80% {
        height: 5px;
        background: #999797;
    }
    90% {
        height: 3px;
        background: #999797;
    }
    100% {
        height: 1px;
        background: #999797;
    }
}
</style>
