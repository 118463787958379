




import { Component, Vue } from 'vue-property-decorator';
import StickyPlayer from './components/sticky-player/StickyPlayer.vue';

@Component({
    components: {
        StickyPlayer
    }
})
export default class App extends Vue {}
